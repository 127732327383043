import { Input } from "antd";
const CustomInputText = (props) => {
  const {
    label,
    marginLeft,
    marginRight,
    value,
    data,
    setData,
    name,
    isRequired,
    isValid = true,
    chartId,
    isNumber = false,
  } = props;
  const changeHandler = (e) => {
    if (chartId) {
      setData(
        {
          ...data,
          ...(isNumber ? {[name]: e.target.value?.replace(/[^0-9]/g, "")} : {[name]: e.target.value} )
        },
        chartId
      );
      return;
    }
    if (e.target.value.trim().length === 0) {
      setData({
        ...data,
        [name]: "",
      });
    } else {
      setData({
        ...data,
        ...(isNumber ? {[name]: e.target.value?.replace(/[^0-9]/g, "")} : {[name]: e.target.value} ),
      });
    }
  };
  return (
    <div style={{ flex: 1, marginLeft, marginRight }}>
      <p className="sidenav-heading1">{label}</p>
      <Input
        value={value}
        onChange={changeHandler}
        required={isRequired}
        style={{ borderColor: isValid || "red" }}
      />
    </div>
  );
};

export default CustomInputText;
