import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  GETDATA,
  DELETEDATA_ID,
  GET_PROPERTY_DATA_BY_ID,
  GET_FILTER_MAX_VALUES,
  GET_UNDEFINED_DATA_UNITS,
  GET_SOLD_IN_THE_PAST,
  GET_SALES_APPLICATION_NUMBERS,
} from "../constants/PropertyDatabase";
import {
  GET_ALL_COLLECTIONS,
  CREATE_COLLECTION,
  DELETE_COLLECTION_BY_ID,
  GET_COLLECTION_BY_ID,
  DELETE_MULTIPLE_COLLECTION,
} from "redux/constants/Collection";
import {
  setData,
  showDataErrorMessage,
  showDataSuccessMessage,
  deleteDataByIDSuccess,
  getPropertyDataByIdSuccess,
  getPropertyDataByIdfailed,
  setFiterMaxValues,
  resetRemoveButtonState,
  getFilterMaxValueFailed,
  getUndefinedDataUnitsSuccess,
  getUndefinedDataUnitsFailed,
  getSoldInThePastSuccess,
  getSoldInThePastFailed,
  getSalesApplicationNumbersError,
  getSalesApplicationNumbersSuccess,
} from "../actions/PropertyDatabase";
import {
  createCollectionSuccess,
  getAllCollectionsSuccess,
  getAllCollectionsFailed,
  createCollectionFailed,
  deleteCollectionByIdSuccess,
  deleteCollectionByIdFailed,
  getCollectionByIdSuccess,
  deleteMultipleCollectionSuccess,
  deleteMultipleCollectionFailed,
  getCollectionByIdFailed,
} from "../actions/Collection";
import SupabaseService from "services/SupabaseService";
import { GET_COLLECTIONS_DATA_BY_ID } from "redux/constants/CollectionReport";
import {
  getCollectionsDataByIdFailed,
  getCollectionsDataByIdSuccess,
} from "redux/actions/CollectionReport";

export function* getAllData() {
  yield takeEvery(GETDATA, function* () {
    try {
      const { data, error } = yield call(SupabaseService.fetchStreamingData);
      if (error) throw error;
      else {
        yield put(setData(data));
      }
    } catch (error) {
      yield put(showDataErrorMessage("Request Failed, Please try again"));
      console.log(error);
    }
  });
}

export function* getSalesApplicationNumbers() {
  yield takeEvery(GET_SALES_APPLICATION_NUMBERS, function* () {
    try {
      const { data, error } = yield call(
        SupabaseService.getSalesApplicationNumbers
      );
      if (error) throw error;
      else {
        const counts = {};
        data?.forEach((item) => {
          counts[item?.application_number] =
            (counts[item?.application_number] || 0) + 1;
        });

        const duplicateApplicationNumbers = Object.keys(counts)?.filter(
          (key) => counts[key] > 1
        );

        yield put(
          getSalesApplicationNumbersSuccess(duplicateApplicationNumbers)
        );
      }
    } catch (error) {
      yield put(getSalesApplicationNumbersError());
      console.log(error);
    }
  });
}
export function* getUndefinedDataUnits() {
  yield takeEvery(GET_UNDEFINED_DATA_UNITS, function* () {
    try {
      const { data, error } = yield call(SupabaseService.getUndefinedDataUnits);
      if (error) throw error;
      else {
        yield put(getUndefinedDataUnitsSuccess(data));
      }
    } catch (error) {
      yield put(
        getUndefinedDataUnitsFailed("Request Failed, Please try again")
      );
      console.log(error);
    }
  });
}

export function* getsoldInThePast() {
  yield takeEvery(GET_SOLD_IN_THE_PAST, function* () {
    try {
      const { data, error } = yield call(SupabaseService.getSoldInThePast);
      if (error) throw error;
      else {
        yield put(getSoldInThePastSuccess(data));
      }
    } catch (error) {
      yield put(getSoldInThePastFailed("Request Failed, Please try again"));
      console.log(error);
    }
  });
}

export function* getPropertyDataByID() {
  yield takeEvery(GET_PROPERTY_DATA_BY_ID, function* (payload) {
    try {
      const { id } = payload;
      const { data, error } = yield call(SupabaseService.getDataById, id);
      if (error) throw error;
      else {
        yield put(getPropertyDataByIdSuccess(data));
      }
    } catch (error) {
      yield put(getPropertyDataByIdfailed("Request Failed, Please try again"));
    }
  });
}

export function* deleteDataByID() {
  yield takeEvery(DELETEDATA_ID, function* (payload) {
    try {
      const { id } = payload;
      const { error } = yield call(SupabaseService.deleteDataByID, id);
      if (error) throw error;
      else {
        yield put(showDataSuccessMessage("Deleted successfully"));
        yield put(deleteDataByIDSuccess());
      }
    } catch (error) {
      yield put(showDataErrorMessage("Error, Please try again"));
      console.log(error);
    }
  });
}

export function* createNewCollection() {
  yield takeEvery(CREATE_COLLECTION, function* (payload) {
    try {
      const { collectionData } = payload;
      const { data, error } = yield call(
        SupabaseService.createCollection,
        collectionData
      );
      if (error) throw error;
      else {
        yield put(createCollectionSuccess(data));
        yield put(resetRemoveButtonState());
      }
    } catch (error) {
      yield put(createCollectionFailed("Error, Please try again"));
    }
  });
}
export function* getCollectionByID() {
  yield takeEvery(GET_COLLECTION_BY_ID, function* (payload) {
    try {
      const { id } = payload;
      const { data, error } = yield call(SupabaseService.getCollectionById, id);
      if (error) throw error;
      else {
        yield put(getCollectionByIdSuccess(data));
      }
    } catch (error) {
      console.log(error);
      yield put(getCollectionByIdFailed("Error, Please try again"));
    }
  });
}

export function* deleteCollectionByID() {
  yield takeEvery(DELETE_COLLECTION_BY_ID, function* (payload) {
    try {
      const { id } = payload;
      const { error } = yield call(SupabaseService.deleteCollectionByID, id);
      if (error) throw error;
      else {
        yield put(deleteCollectionByIdSuccess("Deleted Successfully"));
      }
    } catch (error) {
      yield put(deleteCollectionByIdFailed("Error, Please try again"));
    }
  });
}

export function* deleteMultipleCollection() {
  yield takeEvery(DELETE_MULTIPLE_COLLECTION, function* (payload) {
    try {
      const { collectionIds } = payload;
      for (var i = 0; i < collectionIds.length; i++) {
        const { error } = yield call(
          SupabaseService.deleteCollectionByID,
          collectionIds[i]
        );
        if (error) throw error;
      }
      const { data, error } = yield call(SupabaseService.getAllCollections);
      if (error) throw error;
      else {
        yield put(deleteMultipleCollectionSuccess(data));
      }
    } catch (error) {
      yield put(deleteMultipleCollectionFailed());
    }
  });
}

export function* getAllCollections() {
  yield takeEvery(GET_ALL_COLLECTIONS, function* () {
    try {
      const { data, error } = yield call(SupabaseService.getAllCollections);
      if (error) throw error;
      else {
        yield put(getAllCollectionsSuccess(data));
      }
    } catch (error) {
      yield put(getAllCollectionsFailed("Request Failed, Please try Again"));
    }
  });
}
export function* getCollectionsByID() {
  yield takeEvery(GET_COLLECTIONS_DATA_BY_ID, function* (payload) {
    try {
      const { id } = payload;
      const { data, error } = yield call(
        SupabaseService.getCollectionsById,
        id
      );
      if (error) throw error;
      else {
        yield put(getCollectionsDataByIdSuccess(data));
      }
    } catch (error) {
      console.log(error);
      yield put(getCollectionsDataByIdFailed("Request Failed, Please Go back"));
    }
  });
}

export function* getFilterMaxValue() {
  yield takeEvery(GET_FILTER_MAX_VALUES, function* () {
    try {
      // const { data, error } = yield call(SupabaseService.getValuesForFilters);
      const data = [];
      const { data: priceData, error: priceError } = yield call(
        SupabaseService.getPriceMaxValue
      );
      if (priceError) throw priceError;
      else data.push(priceData[0]);
      const { data: dateMaxData, error: dateMaxError } = yield call(
        SupabaseService.getDateAddedMaxValue
      );
      if (dateMaxError) throw dateMaxError;
      else data.push(dateMaxData[0]);
      const { data: dateMinData, error: dateMinError } = yield call(
        SupabaseService.getDateAddedMinValue
      );
      if (dateMinError) throw dateMinError;
      else data.push(dateMinData[0]);
      const { data: bedroomsMaxData, error: bedRoomsMaxError } = yield call(
        SupabaseService.getBedroomsMaxValue
      );
      if (bedRoomsMaxError) throw bedRoomsMaxError;
      else data.push(bedroomsMaxData[0]);
      const { data: bathroomsMaxData, error: bathroomsMaxError } = yield call(
        SupabaseService.getBathroomsMaxValue
      );
      if (bathroomsMaxError) throw bathroomsMaxError;
      else data.push(bathroomsMaxData[0]);
      const { data: ARVMaxData, error: ARVMaxError } = yield call(
        SupabaseService.getARVMaxValue
      );
      let arv;
      if (ARVMaxError) throw ARVMaxError;
      else {
        arv = Number(ARVMaxData[0]);
        data.push(arv);
      }
      // const { data: SalesARVMaxData, error: SalesARVMaxError } = yield call(
      //   SupabaseService.getSalesARVMaxValue
      // );
      // if (SalesARVMaxError) throw SalesARVMaxError;
      // else data.push(SalesARVMaxData[0]);
      // const { data: SalesPriceMaxData, error: SalesPriceMaxError } = yield call(
      //   SupabaseService.getSalesPriceMaxValue
      // );
      // if (SalesPriceMaxError) throw SalesPriceMaxError;
      // else data.push(SalesPriceMaxData[0]);

      yield put(setFiterMaxValues(data));
    } catch (error) {
      console.log(error);
      yield put(getFilterMaxValueFailed());
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getAllData),
    fork(getUndefinedDataUnits),
    fork(deleteDataByID),
    fork(createNewCollection),
    fork(getAllCollections),
    fork(getPropertyDataByID),
    fork(deleteCollectionByID),
    fork(getFilterMaxValue),
    fork(getCollectionByID),
    fork(getCollectionsByID),
    fork(deleteMultipleCollection),
    fork(getsoldInThePast),
    fork(getSalesApplicationNumbers),
  ]);
}
