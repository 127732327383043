import {Alert, Button, Modal} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import CustomInputText from "../../../../components/layout-components/CustomInputText";
import CustomDatePicker from "../../../../components/layout-components/CustomDatePicker";
import {citiesOption, propertyTypeOptions, statusFilterOptions} from "../../../../configs/FiltersConfig";
import CustomSelectBox from "../../../../components/layout-components/CustomSelectBox";
import debounce from "lodash/debounce";

const UpdateSaleModal = ({isModalOpen, setIsModalOpen, data, setData, submitHandler, loading, checkApplicationNumberExists}) => {
  const [disable, setDisable] = useState(true)
  const [errorMessage, setErrorMessage] = useState("");

  const debouncedCheckApplicationNumber = useMemo(() => {
    return debounce(async (applicationNumber, salesId) => {
      if (!applicationNumber) {
        setErrorMessage("");
        setDisable(true);
        return;
      }

      try {
        const exists = await checkApplicationNumberExists(
          applicationNumber,
          salesId
        );
        if (exists) {
          setErrorMessage("This Sale Already exists. Please edit it instead");
          setDisable(true);
        } else {
          setErrorMessage("");
          setDisable(false);
        }
      } catch (error) {
        setErrorMessage(
          "An error occurred while checking the application number."
        );
        setDisable(true);
      }
    }, 500); // 500ms delay
  }, [checkApplicationNumberExists]);

  useEffect(() => {
    if (data.application_number) {
      debouncedCheckApplicationNumber(data.application_number, data.sales_id);
    } else {
      setDisable(true)
    }
  }, [data.application_number, data.sales_id, debouncedCheckApplicationNumber]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal
        title={data.sales_id ? 'Edit sale' : 'Add sale'}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <form onSubmit={submitHandler}>
          <div className="form-row">
            <CustomInputText
              label="Application number"
              name="application_number"
              marginRight="10px"
              data={data}
              setData={setData}
              value={data.application_number}
              isRequired={true}
            />

            <CustomDatePicker
              label="Registration date"
              name="registration_date"
              marginLeft="10px"
              data={data}
              setData={setData}
              value={data.registration_date}
            />
          </div>
          <div className="form-row">
            <CustomSelectBox
              label="Parish"
              marginRight="10px"
              optionsList={citiesOption}
              name="parish"
              data={data}
              setData={setData}
              value={data.parish}
            />
            <CustomInputText
              label="Address"
              name="address"
              marginLeft="10px"
              data={data}
              setData={setData}
              value={data.address}
            />
          </div>
          <div className="form-row">
            <CustomInputText
              label="Parcel area"
              name="parcel_area"
              marginRight="10px"
              data={data}
              setData={setData}
              value={data.parcel_area}
            />
            <CustomInputText
              label="Parcel area ha"
              name="parcel_area_ha"
              marginLeft="10px"
              data={data}
              setData={setData}
              value={data.parcel_area_ha}
              isNumber={true}
            />
          </div>
          <div className="form-row">
            <CustomDatePicker
              label="Acquisition date"
              name="acquisition_date"
              marginRight="10px"
              data={data}
              setData={setData}
              value={data.acquisition_date}
            />
            <CustomInputText
              label="Price"
              name="price"
              marginLeft="10px"
              data={data}
              setData={setData}
              value={data.price}
              isNumber={true}
            />
          </div>
          <div className="form-row">
            <CustomInputText
              label="Combined ARV"
              name="combined_arv"
              marginRight="10px"
              data={data}
              setData={setData}
              value={data.combined_arv}
              isNumber={true}
            />
            <CustomSelectBox
              label="Property type"
              marginRight="10px"
              optionsList={propertyTypeOptions}
              name="property_type"
              data={data}
              setData={setData}
              value={data.property_type}
            />
          </div>
          <div className="form-row">
            <CustomSelectBox
              label="Status"
              marginRight="10px"
              optionsList={statusFilterOptions}
              name="status"
              data={data}
              setData={setData}
              value={data.status}
            />
            <CustomInputText
              label="ARV"
              name="arv"
              marginLeft="10px"
              data={data}
              setData={setData}
              value={data.arv}
              isNumber={true}
            />
          </div>
          <div className="form-row" style={{ display: 'flex', justifyContent: 'space-between' }}>
            {errorMessage ? <Alert message={errorMessage} type="error" showIcon /> : <div></div>}
            <Button
              disabled={disable}
              loading={loading}
              type="primary"
              htmlType="submit"
              className="button-text"
            >
              Save
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  )
}

export default UpdateSaleModal