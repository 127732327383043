import { Select } from "antd";
const CustomSelectBox = (props) => {
  const {
    label,
    optionsList,
    marginLeft,
    marginRight,
    name,
    data,
    setData,
    value,
    chartId,
  } = props;
  const selectProps = {
    style: {
      flex: 1,
    },
    value,
    options: optionsList,
    maxTagCount: "responsive",
    onChange: (newValue) => {
      if (chartId) {
        if (typeof data === typeof {}) {
          setData({ ...data, [name]: newValue }, chartId);
        } else {
          setData(newValue, chartId);
        }
      } else {
        if (typeof data === typeof {}) {
          setData({ ...data, [name]: newValue });
        } else {
          setData(newValue);
        }
      }
    },
  };

  return (
    <div
      style={{
        flex: 1,
        marginLeft,
        marginRight,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p className="sidenav-heading1">{label}</p>
      <Select {...selectProps} />
    </div>
  );
};

export default CustomSelectBox;
