import {Button, Col, Row, Space, Table} from "antd";
import { dateFormatHandler } from "configs/TableConstants";
import React from "react";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

const PropertyDetailsTimeline = ({ propertyData, openSaleModal, openDeleteSaleModal }) => {
  const columns = [
    {
      title: "Sold",
      render: () => <p className="property-database-heading8">Sold</p>,
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (price) => {
        if (price) {
          return (
            <p className="property-database-heading8">
              ${price.toLocaleString()}
            </p>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      title: "Date",
      dataIndex: "registration_date",
      render: (registration_date) => {
        const dateValue = dateFormatHandler(new Date(registration_date));
        return <p className="property-database-heading8">{dateValue}</p>;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => openSaleModal(record)}><EditOutlined /></Button>
          <Button danger types="primary" onClick={() => openDeleteSaleModal(record)}><DeleteOutlined /></Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ marginTop: "30px" }}>
      <Row justify="space-between" align="middle" style={{ width: '100%' }}>
        <Col>
          <p className="property-database-heading7">Timeline</p>
        </Col>
        <Col>
          <Button type="primary" onClick={() => openSaleModal()}>
            + Add Sale
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={propertyData.sales}
        columns={columns}
        rowKey="sales_id"
        tableLayout="fixed"
        pagination={false}
      />
    </div>
  );
};

export default PropertyDetailsTimeline;
