import { DatePicker } from "antd";
import moment from "moment";

const CustomDatePicker = (props) => {
  const {
    label,
    marginLeft,
    marginRight,
    value,
    data,
    setData,
    name,
    isRequired,
  } = props;
  const dateFormat = "MM/DD/YYYY";
  const dobDateHandler = (date, dateString) => {
    const newDate = date.format("YYYY-MM-DD");
    setData({ ...data, [name]: newDate });
  };

  return (
    <div style={{ flex: 1, marginLeft, marginRight }}>
      <p className="sidenav-heading1">{label}</p>
      <DatePicker
        required={isRequired}
        value={
          value ? moment(new Date(value), dateFormat) : ""
        }
        format={dateFormat}
        onChange={dobDateHandler}
        allowClear={false}
        getPopupContainer={(trigger) => trigger.parentElement}
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default CustomDatePicker;
