import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import {Checkbox, Modal, Spin} from "antd";
//user-imports
import MainWrapper from "components/property-database-compnents/MainWrapper";
import {
  hideFilters,
  deleteDataByID,
  getPropertyDataById,
  showLoading,
  hideDataMessage,
  showModal,
  hideModal,
  preventApplyingFilters,
} from "redux/actions/PropertyDatabase";
import { getAllCollections } from "redux/actions/Collection";
import PropertyDetailsHeading from "./PropertyDetailsHeading";
import PropertyDetailsCarousel from "./PropertyDetailsCarousel";
import PropertyDetailsSummary from "./PropertyDetailsSummary";
import PropertyDetailsDescription from "./PropertyDetailsDescription";
// import PropertyDetailsExternalLinks from "./PropertyDetailsExternalLinks";
import PropertyDetailsAgents from "./PropertyDetailsAgents";
import PropertyDetailsTimeline from "./PropertyDetailsTimeline";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import AlertMessage from "components/layout-components/AlertMessage";
import {DeleteErrorModal, ModalContent} from "components/layout-components/CustomModal";
import PropertyDetailsListings from "./PropertyDetailsListings";
import SalesGroup from "./SalesGroup";
import { groupSalesValidator } from "configs/TableConstants";
import PropertyDetailsListed from "./PropertyDetailsListed";
import UpdateSaleModal from "./UpdateSaleModal";
import { supabase } from "auth/SupabaseClient";

const PropertyDetails = (props) => {
  const {
    data,
    dataById,
    hideFilters,
    dataMessage,
    dataShowErrorMessage,
    hideDataMessage,
    allowRedirect,
    getPropertyDataById,
    dataByIdError,
    dataByIdMessage,
    modalIsValid,
    hideModal,
    deleteDataByID,
    preventApplyingFilters,
    backTodatabaseIsVaild,
    getAllCollections,
    collectionId,
    session,
    sales_applicationNumbers,
  } = props;
  const initData = {
    assessment_number: null,
    application_number: null,
    registration_date: null,
    parish: null,
    address: null,
    parcel_area: null,
    parcel_area_ha: null,
    acquisition_date: null,
    price: null,
    combined_arv: null,
    property_type: null,
    status: null,
    user_action: null,
  }

  const history = useHistory();
  const [isSaleModalOpen, setIsSaleModalOpen] = useState(false);
  const [isDeleteSaleModalOpen, setIsDeleteSaleModalOpen] = useState(false);
  const [isPermanentDelete, setIsPermanentDelete] = useState(false);
  const [saleData, setSaleData] = useState(initData)
  const [saleDataLoading, setSaleDataLoading] = useState(false)
  const { propertyId } = useParams();

  useEffect(() => {
    if (allowRedirect) history.push(`${APP_PREFIX_PATH}/home`);
    //eslint-disable-next-line
  }, [allowRedirect]);

  useEffect(() => {
    hideFilters();
    getPropertyDataById(+propertyId);
    preventApplyingFilters();
    if (!backTodatabaseIsVaild) {
      getAllCollections();
    }

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (dataShowErrorMessage) {
        hideDataMessage();
      }
    }, 3000);
    //eslint-disable-next-line
  }, [dataShowErrorMessage]);

  const openSaleModal = (record = null) => {
    setSaleData({
      ...initData,
      assessment_number: dataById ? dataById[0].assessment_number : null,
      ...record,
      user_action: record ? 'updated' : 'added',
    });
    setIsSaleModalOpen(true);
  };

  const openDeleteSaleModal = (record = null) => {
    if (!record) return
    setSaleData(record);
    setIsDeleteSaleModalOpen(true);
  };

  const backHandler = () => {
    if (backTodatabaseIsVaild) {
      history.push("/app/home");
    } else {
      history.push(`${APP_PREFIX_PATH}/collections/${collectionId}`);
    }
  };

  const submitSaleHandler = async (event) => {
    event.preventDefault();
    setSaleDataLoading(true)
    try {
      const { error } = await supabase
        .from('sales') // Replace 'sales' with your table name
        .upsert(saleData, { onConflict: 'sales_id' }); // Use `sales_id` as the conflict key

      if (error) {
        console.error('Error upserting sale:', error);
        setSaleDataLoading(false)
        return;
      }

      getPropertyDataById(+propertyId);
      setIsSaleModalOpen(false);
      setSaleDataLoading(false)
    } catch (err) {
      console.error('Error in upsert operation:', err);
      setSaleDataLoading(false)
    }
  };

  const deleteSaleHandler = async () => {
    setSaleDataLoading(true)
    try {
      let error;
      if (isPermanentDelete) {
        ({ error } = await supabase
          .from('sales')
          .delete()
          .eq('sales_id', saleData.sales_id));
      } else {
        ({ error } = await supabase
          .from('sales')
          .update({
            user_action: "deleted",
          })
          .eq('sales_id', saleData.sales_id));
      }

      if (error) {
        console.error('Error deleting sale:', error);
        setSaleDataLoading(false)
        return;
      }

      getPropertyDataById(+propertyId);
      setIsDeleteSaleModalOpen(false);
      setSaleDataLoading(false)
    } catch (err) {
      console.error('Error in delete operation:', err);
      setSaleDataLoading(false)
    }
  };

  const checkApplicationNumberExists = async (applicationNumber, excludeSalesId = null) => {
    let query = supabase
      .from("sales")
      .select("sales_id")
      .eq("application_number", applicationNumber);

    if (excludeSalesId) {
      query.neq("sales_id", excludeSalesId)
    }

    if (propertyId) {
      query.eq("assessment_number", propertyId);
    }

    const { data, error } = await query;

    if (error) {
      console.error("Error checking application number:", error);
      return true;
    }

    return data.length > 0;
  }

  const backToDatabase = (
    <div className="back-to-database" onClick={backHandler}>
      <LeftOutlined style={{ fontSize: "12px" }} />{" "}
      <span>
        {backTodatabaseIsVaild
          ? "Back to property database"
          : "Back to collection list"}
      </span>
    </div>
  );
  let groupSalesIsValid = false;
  if (dataById) {
    groupSalesIsValid = groupSalesValidator(
      dataById[0],
      sales_applicationNumbers
    );
  }
  if (!dataById) {
    return (
      <div style={{ textAlign: "center" }}>
        {dataByIdError ? <p>{dataByIdMessage}</p> : <Spin />}
      </div>
    );
  } else {
    return (
      <>
        <AlertMessage
          alertType="error"
          showMessage={dataShowErrorMessage}
          message={dataMessage}
          width="195px"
        />
        <DeleteErrorModal
          modalIsValid={modalIsValid}
          hideModal={hideModal}
          deleteDataByID={deleteDataByID}
          id={dataById[0].assessment_number}
        />
        {session && <>{backToDatabase}</>}

        <MainWrapper marginTop="10px">
          <div>
            <PropertyDetailsHeading
              propertyData={dataById[0]}
              {...props}
              editManualReviewPropertyIsValid={false}
            />
            <PropertyDetailsCarousel images={dataById[0].images} />
            <PropertyDetailsSummary propertyData={dataById[0]} />
            <PropertyDetailsDescription propertyData={dataById[0]} />
            {/* <PropertyDetailsExternalLinks propertyData={dataById[0]} /> */}
            <PropertyDetailsAgents propertyData={dataById[0]} />
            <PropertyDetailsListings propertyData={dataById[0]} />
            <PropertyDetailsListed propertyData={dataById[0]} />
            <PropertyDetailsTimeline
              propertyData={dataById[0]}
              openSaleModal={openSaleModal}
              openDeleteSaleModal={openDeleteSaleModal}
            />

            {groupSalesIsValid && data && (
              <SalesGroup
                propertyData={dataById}
                data={data}
                groupSalesIsValid={groupSalesIsValid}
                sales_applicationNumbers={sales_applicationNumbers}
              />
            )}
          </div>
        </MainWrapper>
        <UpdateSaleModal
          loading={saleDataLoading}
          setIsModalOpen={setIsSaleModalOpen}
          isModalOpen={isSaleModalOpen}
          data={saleData}
          setData={setSaleData}
          checkApplicationNumberExists={checkApplicationNumberExists}
          submitHandler={submitSaleHandler}
        />
        <Modal
          open={isDeleteSaleModalOpen}
          okText="Confirm"
          onOk={deleteSaleHandler}
          confirmLoading={saleDataLoading}
          onCancel={() => setIsDeleteSaleModalOpen(false)}
        >
          <ModalContent
            heading1="Are you sure you want to delete this?"
            heading2="This action can't be undone"
          />
          <Checkbox
            checked={isPermanentDelete}
            onChange={(e) => setIsPermanentDelete(e.target.checked)}
          >
            Delete Permanently
          </Checkbox>

        </Modal>
        {session && <>{backToDatabase}</>}
      </>
    );
  }
};

const mapStateToProps = ({ propertyDatabase, collection, auth }) => {
  const {
    data,
    dataById,
    loading,
    dataByIdLoading,
    dataMessage,
    dataShowErrorMessage,
    dataShowSuccessMessage,
    allowRedirect,
    modalIsValid,
    dataByIdError,
    dataByIdMessage,
    backTodatabaseIsVaild,
    deleteDataLoading,
    sales_applicationNumbers,
  } = propertyDatabase;
  const { collectionId } = collection;
  const { session } = auth;
  return {
    data,
    dataById,
    loading,
    dataByIdLoading,
    dataMessage,
    dataShowErrorMessage,
    dataShowSuccessMessage,
    allowRedirect,
    modalIsValid,
    dataByIdError,
    dataByIdMessage,
    backTodatabaseIsVaild,
    collectionId,
    deleteDataLoading,
    session,
    sales_applicationNumbers,
  };
};
const mapDispatchToProps = {
  hideFilters,
  deleteDataByID,
  getPropertyDataById,
  showLoading,
  hideDataMessage,
  showModal,
  hideModal,
  preventApplyingFilters,
  getAllCollections,
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails);
